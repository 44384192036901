import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Modal from 'src/ui/modal/modal';


class PositionSelector extends Component {
  state = {
    position: this.props.position,
    editMode: false,
    vertical: this.props.position.split(' ')[1].replace('%', ''),
    horizontal: this.props.position.split(' ')[0].replace('%', ''),
  }

  get position(){
    return `${this.state.horizontal}% ${this.state.vertical}%`
  }

  handleOpen = () => {
    this.setState({ editMode: true })
  }

  handleClose = () => {
    console.log(this.state)
    this.setState({ editMode: false })
  }

  handleSelect = (field, { target: { value } }) =>{
    this.setState({
      [field]: value,
      position: this.position
    })
  }

  handleSave = () => {
    global.Api.patch(
      this.props.updateUrl,
      {
        post: {
          position: this.position
        }
      }
    ).then((res)=>{
      if(res.status == 'success'){
        this.setState({ editMode: false })
      }
    }).catch((error)=>{
      console.error(error)
    })

  }

  renderRange(field) {
    return(
      <input
        type="range"
        className="form-range"
        value={this.state[field]}
        step="10"
        min="0"
        max="100"
        onChange={this.handleSelect.bind(this, field)}
      />
    )
  }

  renderEdit()  {
    const { imageUrl } = this.props;

    return (
      <Modal
        title="Postition adjust"
        onClose={this.handleClose}
        buttons={[{
          label: 'Save changes',
          className: 'btn-primary',
          onClick: this.handleSave
        },{
          label: 'Close',
          className: 'btn-secondary',
          onClick: this.handleClose
        }]}
      >
        <div className="position-selector__container">
          <img
            src={imageUrl}
            className='card-image'
            style={{objectPosition: this.position }}
          />
          <div className="vertical">
            {this.renderRange('vertical')}
          </div>

          <div className="horizontal">
            {this.renderRange('horizontal')}
          </div>
        </div>
      </Modal>
    )
  }

  render() {
    const { editMode } = this.state;
    const { imageUrl } = this.props;
    return (
      <div>
        <img
          onClick={this.handleOpen}
          src={imageUrl}
          className='card-thumbnail'
          style={{objectPosition: this.position }}
        />
        {editMode && this.renderEdit()}
      </div>
    );
  }
}


PositionSelector.propTypes = {
  id: PropTypes.string,
  imageUrl: PropTypes.string,
  updateUrl: PropTypes.string,
  position: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
};


export default PositionSelector;
