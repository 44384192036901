class Api {
  constructor(basePath= '') {
    this.basePath = basePath;
  }

  doRequest(path, options = null) {
    return new Promise((resolve, reject) => {
      options = {
        ...options,
        headers:{
          ...options.headers,
          'X-CSRF-Token': this._getCSRF()
        }
      }

      fetch(this._prepareURL(path), options)
        .then((res)=>{
          if(res.status == '404') {
            return reject();
          }

          if (res.redirected) {
            return window.location.href = res.url;
          }

          return res.json()
        })
        .then(resolve)
        .catch((error) => {
          console.log(error);
          reject(error);
        })
    });
  }

  get(path) {
    return this.doRequest(path)
  }

  put(path, params) {
    return this.doRequest(path, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(params)
    })
  }

  patch(path, params) {
    return this.doRequest(path, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(params)
    })
  }


  post(path, params) {
    return this.doRequest(path, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(params)
    })
  }

  _getCSRF(){
    return document.querySelector('meta[name="csrf-token"]').getAttribute('content')
  }

  _prepareURL(path) {
    return `${this.basePath}${path}`;
  }
}

export default Api;
