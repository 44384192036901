import  '../stylesheets/application.scss';

import 'bootstrap';

import 'src/configs/rails'
import 'src/components/root'
import 'src/components/position_selector'
import "bootstrap-icons/font/bootstrap-icons.scss";


import Api from 'src/configs/api';

global.Api = new Api;
