import React, { Component }  from 'react';

class Modal extends Component  {
  renderHeader() {
    return (
      <div className="modal-header">
        <h5 className="modal-title">{this.props.title}</h5>

        {this.props.onClose && (
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={this.props.onClose.bind(null)}
          >
            <span aria-hidden="true"></span>
          </button>
        )}
      </div>
    )
  }

  renderButton(props, index) {
    return (
      <button
        key={`btn-${index}`}
        type="button"
        className={ `btn ${props.className}` }
        onClick={ props.onClick.bind(null) }
      >
        { props.label }
      </button>
    )
  }

  renderButtons() {
    return this.props.buttons && this.props.buttons.map(this.renderButton);
  }

  renderFooter() {
    return(
      <div className="modal-footer">
        {this.renderButtons()}
      </div>
    );
  }

  render() {
    return(
      <div className="modal show" >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            {this.renderHeader()}

            <div className="modal-body">
              {this.props.children}
            </div>

            {this.renderFooter()}
          </div>
        </div>
      </div>
    )
  }
}


export default Modal;
