import React from 'react';
import { safeRender } from 'src/render';

const App = () => {


  return (
    <div className="center">
      BASE COMPONENT
    </div>
  );
};

safeRender('#app', App);
