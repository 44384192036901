import React from 'react';
import { render } from 'react-dom';

const safeRender = (target, Component) => {
  let targetDOM = target;

  if(typeof target === 'string' || target instanceof String){
    targetDOM = document.querySelector(target);
  }

  if(targetDOM) {
    render(<Component {...targetDOM.dataset} />, targetDOM)
  } else {
    console.log(`DOMElement ${target} not found!!`);
  }
}

const onSafeToRender = (target, Component) =>{
  document.addEventListener('DOMContentLoaded', ()=>{
    safeRender(target, Component);
  })
}

export { safeRender, onSafeToRender };
